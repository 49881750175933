/* stylelint-disable */

@font-face {
	font-family: 'Sweet Sans';
	font-display: swap;
	src: url('../../../fonts/Sweet-Sans.woff2') format('woff2');
}

@font-face {
	font-family: 'Sweet Sans Bold';
	font-display: swap;
	src: url('../../../fonts/Sweet-Sans-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Password';
	font-display: swap;
	src: url('../../../fonts/Password.woff2') format('woff2');
}

@font-face {
	font-family: 'Sweet Sans Medium';
	font-display: swap;
	src: url('../../../fonts/Sweet-Sans-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'Druk Web';
	font-display: swap;
	src: url('../../../fonts/Druk-Medium-Web.woff2') format('woff2');
}

@font-face {
	font-family: 'beyond_infinityregular';
	font-display: swap;
	src: url('../../../fonts/beyond_infinity-webfont.woff2') format('woff2');
}

@font-face {
	font-family: 'UniversLT-CondensedBold';
	font-display: swap;
	src: url('../../../fonts/UniversLTCondensedBold.woff2') format('woff2');
}

@font-face {
	font-family: 'UniversLTPro-LightCond';
	font-display: swap;
	src: url('../../../fonts/UniversLTProLightCond.woff2') format('woff2');
}

/*
#Icon font
##SCSS file (_fonts.scss) is automaticaly generated with a Gulp using lodash template(_fonts.tpl). Don't touch it.
Font generates from SVG icons which are in 'fonts/icons' folder
All SVG icons must be of the same height (the most common one): 32px or 64px for example
If they're not we have two options:
1) normalize SVG icons to make them all equal
2) ask a client to provide new SVG icons with the right size
To generate fonts from SVG icons run Gulp task
```
gulp iconfont
```
*/

@font-face {
	font-family: 'icons';
	font-display: swap;
	src: url('../../../fonts/icons.woff2') format('woff2');
}
